<template>
  <div class="grid">
    <div class="col-12 lg:col-4">
      <Card>
        <template #content>
          <AbstractForm
            ref="form"
            :loading="formLoading"
            v-model="searchForm"
            :on-commit="searchBlocksInfo"
            :after-clear="() => {this.blocks = undefined}"
            commit-label="Найти"
            commit-icon="pi pi-search"
          ></AbstractForm>
        </template>
      </Card>
    </div>
    <div class="col-12 lg:col-8" v-if="formLoading === false && blocks !== undefined">
      <Card v-if="blocks === null">
        <template #title>Данные по займу</template>
        <template #content>
          <Message severity="warn" :closable="false">Информация по займу не найдена в базе НБКИ</Message>
        </template>
      </Card>

      <CloseLoanBlockInfo v-if="blocks !== null && this.searchForm.action.value === 'closeInfo'" :blocks="blocks"/>
    </div>
  </div>
</template>

<script>
import AbstractForm from "@/components/AbstractForm.vue";
import FormModel from "@/model/FormModel";
import BureauDataService from "@/service/BureauDataService";
import ResponseService from "@/service/ResponseService";
import CloseLoanBlockInfo from "@/components/BlocksInfo/CloseLoanBlockInfo.vue";

export default {
  name: "BlocksInfoForm",
  components: {CloseLoanBlockInfo, AbstractForm},
  data() {
    return {
      formLoading: false,
      searchForm: {
        identity: new FormModel('UUID или числовой ID займа'),
        action: new FormModel('Данные для просмотра', {
          type: 'select',
          selectOptions: [
            { key: 'closeInfo', value: 'Данные о закрытии займа' }
          ],
          selectOption: 'value',
          selectValue: 'key'
        })
      },
      blocks: undefined
    }
  },
  methods: {
    searchBlocksInfo() {
      const identity = this.searchForm.identity.value;
      if (identity === null) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Некорректно заполнена форма',
          detail: 'Укажите UUID или числовой ID займа',
          life: 5000
        })
        return;
      }

      const action = this.searchForm.action.value;
      if (action === null) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Некорректно заполнена форма',
          detail: 'Выберите данные для просмотра',
          life: 5000
        })
        return;
      }

      const request = identity.length === 36 ?
        BureauDataService.getBlocksDataByApplicationUuid(identity) :
        BureauDataService.getBlocksDataByLoanId(parseInt(identity));

      this.formLoading = true
      request.then((res) => {
        if (res.error !== null) {
          this.$toast.add({
            severity: 'warn',
            summary: 'Ошибка запроса данных',
            detail: res.error,
            life: 5000
          })
        }
        this.blocks = res.blocks
      })
      .catch((err) => {
        ResponseService.handleErrorResponseWithForm(err, this.searchForm, this.$toast)
      })
      .finally(() => {
        this.formLoading = false
      })
    },
    clear() {
      this.$refs.form.clear();
    }
  }
}
</script>