<template>
  <Card v-if="blocks['C38'] === undefined">
    <template #title>Данный займ числится открытым в бюро</template>
  </Card>

  <Card v-if="blocks['C38'] !== undefined">
    <template #title>В бюро передана информация о закрытии займа</template>
    <template #content>
      <p v-for="(v, i) in blocks['C38']" :key="i">
        <b>{{v['name']}}: </b> {{ v['value'] }}
      </p>
    </template>
  </Card>
</template>

<script>
export default {
  name: "CloseLoanBlockInfo",
  props: {
    blocks: {
      type: Object,
      required: true
    }
  }
}
</script>