<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Просмотр данных по займу</template>
        <template #content>
          <BlocksInfoForm/>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>

import BlocksInfoForm from "@/components/Forms/BlocksInfoForm.vue";

export default {
  name: "BlocksInfoView",
  components: {BlocksInfoForm}
}
</script>