import rpc from "@/rpc";

class BureauDataService {
    rpc

    constructor(rpc) {
        this.rpc = rpc
    }

    getBlocksDataByLoanId(id) {
        return this.rpc.execute('bureau.getBlocksData', {
            loanId: id,
            applicationUuid: null
        });
    }

    getBlocksDataByApplicationUuid(uuid) {
        return this.rpc.execute('bureau.getBlocksData', {
            loanId: null,
            applicationUuid: uuid
        });
    }
}

export default new BureauDataService(rpc)